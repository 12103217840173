import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../redux/client-slice';
import { analyticsActions } from '../../../redux/analytics/analyticsSlice';
import { personasActions } from '../../../redux/analytics/personasSlice';
import { copiesActions } from '../../../redux/analytics/copiesSlice';
import { sequenceStatusActions } from '../../../redux/analytics/sequenceStatusSlice';
import { searchActions } from '../../../redux/searchSlice';
import { fuelStationIdActions } from '../../../redux/fuelStationIdSlice';
import { SpinnerDiamond } from 'spinners-react';
import { clientTypeActions } from '../../../redux/clientType-slice';
import './clientsTab.scss';
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';
import { SpinnerCircular } from 'spinners-react';

import trailingSpaceRemover, {
  firstSpaceRemover,
} from '../../../utils/trailingSpaceRemover';
import auth from '../../../firebase/init';
import axios from 'axios';

interface IClientsTab {
  loading: boolean;
  activeClients: string[];
  pausedClients: string[];
  onboardingClients: string[];
  offboardingClients: string[];
  allSaasClients: any[];
  totalClients: number;
  updateReloadClients: () => void;
  setActiveTab: () => void;
}

interface ISelector {
  type: string;
  payload: Record<string, unknown>;
}

interface IFuelStationIdSlice {
  fuelId: ISelector;
}

interface IType {
  type: string;
  payload: any;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  fuelStationId: IFuelStationIdSlice;
  clienttype: IClientTypeSlice;
}

interface ID {
  id: string;
  uuid?: string;
}

interface Type {
  type: string;
}

interface AllTypes {
  [key: string]: Type;
}

interface AllIDs {
  [key: string]: ID;
}

const ClientsTab = ({
  loading,
  activeClients,
  pausedClients,
  onboardingClients,
  offboardingClients,
  allSaasClients,
  totalClients,
  setActiveTab,
  updateReloadClients,
}: IClientsTab) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onboardingVisible, setOnboardingVisible] = useState(false);
  const [pausedVisible, setPausedVisible] = useState(false);
  const [offboardingVisible, setOffboardingVisible] = useState(false);
  const [clientNameToAdd, setClientNameToAdd] = useState('');
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const [clientAdditionAllowed, setClientAdditionAllowed] = useState(true);
  const [addNewClientloading, setAddNewClientLoading] = useState(false);
  const [addCampaignsPopupShowing, setAddCampaignsPopupShowing] =
    useState(false);

  const handleClientNameChange = (event: any) => {
    setClientNameToAdd(event.target.value);
  };

  const fuelStationRedux = useSelector(
    (state: IStore) => state.fuelStationId.fuelId
  );

  const clientTypeRedux = useSelector(
    (state: IStore) => state.clienttype.clientType
  );

  const directToCampaignLinkingTab = () => {
    setAddCampaignsPopupShowing(false);
    setActiveTab();
  };

  const closeAddCampaignPopup = () => {
    setAddCampaignsPopupShowing(false);
  };

  useEffect(() => {
    if (allSaasClients && totalClients) {
      allSaasClients.length >= totalClients
        ? setClientAdditionAllowed(false)
        : null;
    }
  }, [totalClients, allSaasClients]);

  const addClient = (e: any) => {
    e.preventDefault();
    setAddNewClientLoading(true);

    const data = {
      pid: Cookies.get('parent'),
      client_name: trailingSpaceRemover(firstSpaceRemover(clientNameToAdd)),
    };
    if (clientNameToAdd.length > 0) {
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/v2/client',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setAddCampaignsPopupShowing(true);
                updateReloadClients();
                setAddNewClientLoading(false);
              })
              .catch((error) => {
                showErrorToast(
                  'Client addition failed, please try again later.'
                );
                setAddNewClientLoading(false);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  };

  const navigateToPage = (clientName: string, clientUuid: string) => {
    resetClientAnalyticsData();
    resetClientCopyData();
    resetClientPersonasData();
    resetSequenceStatusData();
    Cookies.set('inconsistentClientName', 'false', { expires: 1 });
    Cookies.set('displayName', clientName, { expires: 1 });
    getClientId(clientUuid);
    navigate(`/admin/analytics/${clientName}`);
  };

  const getClientId = (clientUuid: string) => {
    if (Cookies.get('parent') === '18') {
      const allIDs = fuelStationRedux?.payload as AllIDs;
      const allTypes = clientTypeRedux?.payload as AllTypes;
      const currentClient = Cookies.get('displayName');
      const currentID = allIDs[currentClient]?.id;
      const currentType = allTypes[currentClient]?.type;
      Cookies.set('clientType', currentType, { expires: 1 });
      dispatch(clientTypeActions.setClientType(currentType));
      Cookies.set('fuelStationId', currentID, { expires: 1 });
    } else {
      Cookies.set('fuelStationId', clientUuid, { expires: 1 });
      Cookies.set('clientType', 'SaaS', { expires: 1 });
      dispatch(clientTypeActions.setClientType('SaaS'));
    }
  };

  const resetClientAnalyticsData = () => {
    dispatch(clientActions.setImageUrl(''));
    dispatch(analyticsActions.setLeads(null));
    dispatch(analyticsActions.setEmailRates(null));
    dispatch(analyticsActions.setProjectedLeads(null));
    dispatch(analyticsActions.setSequenceVolumes(null));
    dispatch(analyticsActions.setTotalLeads(null));
    dispatch(analyticsActions.setSequences(null));
    dispatch(analyticsActions.setTopPersona(null));
    dispatch(analyticsActions.setTopSequence(null));
    dispatch(searchActions.setContactsData(null));
    dispatch(searchActions.setInterestedContactsData(null));
  };

  const resetSequenceStatusData = () => {
    dispatch(clientActions.setImageUrl(''));
    dispatch(sequenceStatusActions.setLeads(null));
    dispatch(sequenceStatusActions.setEmailRates(null));
    dispatch(sequenceStatusActions.setSequenceVolumes(null));
    dispatch(sequenceStatusActions.setTotalLeads(null));
    dispatch(sequenceStatusActions.setSequences(null));
    dispatch(sequenceStatusActions.setTopPersona(null));
    dispatch(sequenceStatusActions.setTopSequence(null));
  };

  const resetClientPersonasData = () => {
    dispatch(personasActions.setTableData(null));
    dispatch(personasActions.setIndustryData(null));
    dispatch(personasActions.setBusinessSizeData(null));
    dispatch(personasActions.setTitleData(null));
    dispatch(personasActions.setSequences(null));
  };

  const resetClientCopyData = () => {
    dispatch(copiesActions.setBestWorstCopy(null));
    dispatch(copiesActions.setCopyRates(null));
    dispatch(copiesActions.setCopyTableData(null));
  };

  return (
    <div className="client-list-container">
      <ToastContainer />
      {fuelStationRedux && Cookies.get('parent') === '18' ? (
        <div>
          <div className="heading-section">
            <p className="client-list-header">Clients</p>
          </div>

          <div className="onboarding-clients-container">
            <div
              className="onboarding-header-row"
              onClick={() => setOnboardingVisible(!onboardingVisible)}
            >
              <p className="onboarding-header">Onboarding</p>
              {/* DOWN ARROW SVG */}
              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                className={[
                  'down-arrow',
                  onboardingVisible ? 'expanded' : '',
                ].join(' ')}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 11.9933C8.78875 11.9941 8.57945 11.9515 8.38416 11.868C8.18887 11.7844 8.01146 11.6617 7.86214 11.5067L0.494998 3.84002C0.221299 3.5225 0.0752004 3.10835 0.0866892 2.68263C0.0981781 2.2569 0.266382 1.8519 0.556777 1.55075C0.847171 1.2496 1.23772 1.07516 1.64824 1.06324C2.05876 1.05133 2.4581 1.20285 2.76428 1.48668L9.02571 7.97334L15.2807 1.48668C15.4295 1.33168 15.6063 1.20866 15.801 1.12473C15.9957 1.04079 16.2045 0.997589 16.4154 0.997589C16.6262 0.997589 16.835 1.04079 17.0297 1.12473C17.2245 1.20866 17.4013 1.33168 17.55 1.48668C17.701 1.64002 17.821 1.82306 17.9029 2.02512C17.9849 2.22717 18.0271 2.44415 18.0271 2.66334C18.0271 2.88253 17.9849 3.09951 17.9029 3.30156C17.821 3.50362 17.701 3.68668 17.55 3.84002L10.1379 11.5067C9.98875 11.662 9.81138 11.7849 9.61604 11.8685C9.4207 11.952 9.21129 11.9945 9 11.9933Z"
                  fill="#ccd4d7"
                />
              </svg>
            </div>

            {onboardingVisible
              ? onboardingClients?.map((client: string, index) => {
                  return (
                    <div
                      key={index}
                      className="client-table-item"
                      onClick={() => navigateToPage(client, '')}
                    >
                      <hr />
                      {client}
                    </div>
                  );
                })
              : null}
          </div>
          <div className="onboarding-clients-container">
            <div
              className="onboarding-header-row"
              onClick={() => setPausedVisible(!pausedVisible)}
            >
              <p className="onboarding-header">Paused</p>
              {/* DOWN ARROW SVG */}
              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                className={['down-arrow', pausedVisible ? 'expanded' : ''].join(
                  ' '
                )}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 11.9933C8.78875 11.9941 8.57945 11.9515 8.38416 11.868C8.18887 11.7844 8.01146 11.6617 7.86214 11.5067L0.494998 3.84002C0.221299 3.5225 0.0752004 3.10835 0.0866892 2.68263C0.0981781 2.2569 0.266382 1.8519 0.556777 1.55075C0.847171 1.2496 1.23772 1.07516 1.64824 1.06324C2.05876 1.05133 2.4581 1.20285 2.76428 1.48668L9.02571 7.97334L15.2807 1.48668C15.4295 1.33168 15.6063 1.20866 15.801 1.12473C15.9957 1.04079 16.2045 0.997589 16.4154 0.997589C16.6262 0.997589 16.835 1.04079 17.0297 1.12473C17.2245 1.20866 17.4013 1.33168 17.55 1.48668C17.701 1.64002 17.821 1.82306 17.9029 2.02512C17.9849 2.22717 18.0271 2.44415 18.0271 2.66334C18.0271 2.88253 17.9849 3.09951 17.9029 3.30156C17.821 3.50362 17.701 3.68668 17.55 3.84002L10.1379 11.5067C9.98875 11.662 9.81138 11.7849 9.61604 11.8685C9.4207 11.952 9.21129 11.9945 9 11.9933Z"
                  fill="#ccd4d7"
                />
              </svg>
            </div>

            {pausedVisible
              ? pausedClients?.map((client: string, index) => {
                  return (
                    <div
                      key={index}
                      className="client-table-item"
                      onClick={() => navigateToPage(client, '')}
                    >
                      <hr />
                      {client}
                    </div>
                  );
                })
              : null}
          </div>
          <div className={loading ? '' : 'clients-table'}>
            {activeClients?.map((client: string, index) => {
              return (
                <div
                  key={index}
                  className="client-table-item"
                  onClick={() => navigateToPage(client, '')}
                >
                  {client}
                  <hr />
                </div>
              );
            })}
          </div>
          <div className="onboarding-clients-container">
            <div
              className="onboarding-header-row"
              onClick={() => setOffboardingVisible(!offboardingVisible)}
            >
              <p className="onboarding-header">Offboarding</p>
              {/* DOWN ARROW SVG */}
              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                className={[
                  'down-arrow',
                  offboardingVisible ? 'expanded' : '',
                ].join(' ')}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 11.9933C8.78875 11.9941 8.57945 11.9515 8.38416 11.868C8.18887 11.7844 8.01146 11.6617 7.86214 11.5067L0.494998 3.84002C0.221299 3.5225 0.0752004 3.10835 0.0866892 2.68263C0.0981781 2.2569 0.266382 1.8519 0.556777 1.55075C0.847171 1.2496 1.23772 1.07516 1.64824 1.06324C2.05876 1.05133 2.4581 1.20285 2.76428 1.48668L9.02571 7.97334L15.2807 1.48668C15.4295 1.33168 15.6063 1.20866 15.801 1.12473C15.9957 1.04079 16.2045 0.997589 16.4154 0.997589C16.6262 0.997589 16.835 1.04079 17.0297 1.12473C17.2245 1.20866 17.4013 1.33168 17.55 1.48668C17.701 1.64002 17.821 1.82306 17.9029 2.02512C17.9849 2.22717 18.0271 2.44415 18.0271 2.66334C18.0271 2.88253 17.9849 3.09951 17.9029 3.30156C17.821 3.50362 17.701 3.68668 17.55 3.84002L10.1379 11.5067C9.98875 11.662 9.81138 11.7849 9.61604 11.8685C9.4207 11.952 9.21129 11.9945 9 11.9933Z"
                  fill="#ccd4d7"
                />
              </svg>
            </div>

            {offboardingVisible
              ? offboardingClients?.map((client: string, index) => {
                  return (
                    <div
                      key={index}
                      className="client-table-item"
                      onClick={() => navigateToPage(client, '')}
                    >
                      <hr />
                      {client}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <div>
          <form onSubmit={addClient} className="client-addition">
            {addCampaignsPopupShowing ? (
              <div className="linking-campaigns-popup">
                <p>
                  Client added successfully, would you like to link campaigns
                  now?
                </p>
                <div className="linking-campaigns-popup__buttons">
                  <button
                    onClick={() => {
                      directToCampaignLinkingTab();
                    }}
                    className="sequence-button"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      closeAddCampaignPopup();
                    }}
                    className="sequence-button"
                  >
                    No
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <label htmlFor="clientAddition">Add Client: </label>
            <input
              type="text"
              name="clientAddition"
              id="clientAddition"
              placeholder="Client Name"
              onChange={handleClientNameChange}
              disabled={!clientAdditionAllowed}
            />
            <button
              type="submit"
              className="sequence-button"
              disabled={!clientAdditionAllowed}
            >
              Submit
            </button>

            <div className="client-addition__loading">
              {addNewClientloading ? (
                <SpinnerCircular
                  size={40}
                  thickness={200}
                  speed={100}
                  color="var(--sliderEnd)"
                  secondaryColor="var(--sliderStart)"
                  className="sign-in-spinner"
                />
              ) : null}
            </div>

            {clientAdditionAllowed ? (
              <p className="client-addition__message">
                Link campaigns to newly created clients in the Campaign Linking
                tab
              </p>
            ) : (
              <p className="client-addition__message">
                Please upgrade your package in order to add more clients
              </p>
            )}
          </form>
          <div className="onboarding-clients-container onboarding-clients-container-sass">
            {allSaasClients?.slice(0, totalClients).map((client, index) => {
              return (
                <div
                  key={index}
                  className="client-table-item"
                  onClick={() => navigateToPage(client.name, client.uuid)}
                >
                  {client.name}
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsTab;
