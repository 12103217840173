// Fundamentals
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import auth from '../../firebase/init';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../redux/auth-slice';

// Styles
import './settingsTab.scss';

//Components
import ProfileSettings from '../../components/settings/profileSettings/profileSettings';
import TeamMemberSettings from '../../components/settings/teamMemberSettings/teamMemberSettings';
import BillingSettings from '../../components/settings/billingSettings/billingSettings';
import ProfileImageUpdaterModal from '../../components/profileImageUpdaterModal/profileImageUpdaterModal';

//Images
import upload from '../../assets/settingsPages/upload-company-logo.svg';
import CompanyProfileSettings from '../../components/settings/companyProfileSettings/companyProfileSettings';

interface IStripeId {
  type: string;
  payload: string;
}

interface IAuthSlice {
  stripeId: IStripeId;
}

interface IStore {
  auth: IAuthSlice;
}

interface ISettings {
  isAdmin: boolean;
}

const SettingsTab = ({ isAdmin }: ISettings) => {
  const dispatch = useDispatch();
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [error, setError] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [profileImageUpdaterVisible, setProfileImageUpdaterVisible] =
    useState(false);
  const stripeId = useSelector((state: IStore) => state.auth.stripeId);
  const [selectedButton, setSelectedButton] = useState<string | null>(
    'Invite Client Emails'
  );

  useEffect(() => {
    if (isAdmin) {
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url: apiEndpoint + 'api/client/' + Cookies.get('fuelStationId'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                dispatch(
                  authActions.setStripeId(
                    response.data.data.copies[0].client__stripe_id
                  )
                );
              })
              .catch(() => {
                setError(false);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    } else if (!stripeId?.payload && !isAdmin) {
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url: apiEndpoint + 'api/client/' + Cookies.get('displayName'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                dispatch(
                  authActions.setStripeId(
                    response.data.data.copies[0].client__stripe_id
                  )
                );
              })
              .catch(() => {
                setError(false);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  }, []);

  useEffect(() => {
    Cookies.set('photoURL', imageUrl, { expires: 1 });
  }, [imageUrl]);

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
  };

  const renderComponent = () => {
    switch (selectedButton) {
      case 'My profile':
        return <ProfileSettings />;
      case 'Invite Client Emails':
        return <TeamMemberSettings />;
      case 'Billing':
        return <BillingSettings />;
      case 'Company Profile':
        return <CompanyProfileSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      {profileImageUpdaterVisible && (
        <ProfileImageUpdaterModal
          toggleModal={setProfileImageUpdaterVisible}
          isVisible={profileImageUpdaterVisible}
          setClientImageUrl={setImageUrl}
        />
      )}
      <h1 className="settings-container__page-header">Settings</h1>
      <div className="settings-container__settings-items">
        <div className="settings-container__settings-items__left-side">
          <button
            className={`settings-button ${
              selectedButton === 'My profile' ? 'active-settings-tab' : ''
            }`}
            onClick={() => handleButtonClick('My profile')}
          >
            My profile
          </button>
          {/* 
          <button
            className={`settings-button ${
              selectedButton === 'Company Profile' ? 'active-settings-tab' : ''
            }`}
            onClick={() => handleButtonClick('Company Profile')}
          >
            Company Profile
          </button> */}
          <button
            className={`settings-button ${
              selectedButton === 'Invite Client Emails'
                ? 'active-settings-tab'
                : ''
            }`}
            onClick={() => handleButtonClick('Invite Client Emails')}
          >
            Invite Client Emails
          </button>
          {Cookies.get('clientType') === 'SaaS' ? null : (
            <button
              className={`settings-button ${
                selectedButton === 'Billing' ? 'active-settings-tab' : ''
              }`}
              onClick={() => handleButtonClick('Billing')}
            >
              Billing
            </button>
          )}
        </div>
        <button
          className="sequence-button company-logo"
          onClick={() => setProfileImageUpdaterVisible(true)}
        >
          <img src={upload} alt="" />
          Company Logo
        </button>
      </div>
      <div className="settings-container__settings-body">
        {renderComponent()}
      </div>
    </div>
  );
};

export default SettingsTab;
