import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import clientSlice from './client-slice';
import copyDraftSlice from './copy-draft-slice';
import contactDataSlice from './contact-data-slice';
import searchSlice from './searchSlice';
import variationSearchTermsSlice from './variationSearchTermsSlice';
import analyticsSlice from './analytics/analyticsSlice';
import personasSlice from './analytics/personasSlice';
import copiesSlice from './analytics/copiesSlice';
import sequenceStatusSlice from './analytics/sequenceStatusSlice';
import personasStatusSlice from './analytics/personasStatusSlice';
import fuelStationIdSlice from './fuelStationIdSlice';
import clientTypeSlice from './clientType-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    analytics: analyticsSlice.reducer,
    copies: copiesSlice.reducer,
    client: clientSlice.reducer,
    copyDraft: copyDraftSlice.reducer,
    contactData: contactDataSlice.reducer,
    search: searchSlice.reducer,
    variationSearchTerms: variationSearchTermsSlice.reducer,
    personas: personasSlice.reducer,
    sequenceStatusActive: sequenceStatusSlice.reducer,
    personasStatusActive: personasStatusSlice.reducer,
    fuelStationId: fuelStationIdSlice.reducer,
    clienttype: clientTypeSlice.reducer,
  },
});

export default store;
